<script>
import Layout from "../router/layouts/main";
import appConfig from "@/app.config";

/**
 * Form Layouts component
 */
export default {
  page: {
    title: "Form Layouts",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout,  },
  data() {
    return {
      title: "Form Layouts",
      items: [
        {
          text: "Forms",
          href: "/",
        },
        {
          text: "Form Layouts",
          active: true,
        },
      ],
       selected: null
    };
  },
};
</script>

<template>
  <Layout>
    <router-view/>
    <!--  end row -->
  </Layout>
</template>